// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



body
{
  background-color: #f5f5f5;
}

.container
{
  
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;;;AAIA;;EAEE,yBAAyB;AAC3B;;AAEA;;;EAGE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["\n\n\n\nbody\n{\n  background-color: #f5f5f5;\n}\n\n.container\n{\n  \n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
