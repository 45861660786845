// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


[class*="trackRenderingContainer"]{
    overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/jbrowse/components/genome-browser.css"],"names":[],"mappings":";;;AAGA;IACI,6BAA6B;AACjC","sourcesContent":["\n\n\n[class*=\"trackRenderingContainer\"]{\n    overflow-y: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
